@import "mixins";
@import "variables";
@import "header"; 
@import "projects";
@import "mixins";
@import "contact";
@import "navbar";
@import "about-me";
@import "coding-examples";
@import "scs-scheme.scss";

body {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Helvetica", "Arial", sans-serif;
  letter-spacing: .4px;  
  margin: 0; //Code for the hero image to appear to the edges
}

//I decided to use margin to a container I added to wrap all the content in order to avoid using relative positioning
.content {
  @media only screen and (min-width: $break-m) {
    margin-left: 12rem;
}
}