//Image on top of the page container
.about-me-image-container {
  background-image: url(../images/about.jpg);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  left: 0rem;
	height: 30vh;
  width: 100%;
  @media only screen and (min-width: $break-s) {
    height: 49vh;
}
  @media only screen and (min-width: $break-m) {
    left: 12rem;
    width: calc(100% - 12rem);
}
  @media only screen and (min-width: $break-xl) {
    height: 42vh;
}
}

.gradient-div-about-me {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  position: absolute;
  width: 100%;
  height: inherit;
}

.about-me-image-container h1 {
  color: white;
  text-align: center;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);  
  width: 55%;
  @media screen and (-ms-high-contrast: none) {
    width: auto;
}
  @media only screen and (min-width: $break-s) {
    //top: 22%;
    width: 64%;
    @media screen and (-ms-high-contrast: none) {
      width: auto;
}
}
}


//Stylining of the text in the section
#about-me-text-container {
  @media only screen and (min-width: $break-m) {
    margin-left: 12rem;
}
}

.about-me-text {
  padding: 1rem;
  line-height: 30px;
  text-align: left;
  @media only screen and (min-width: $break-m) {
    //margin-left: 12rem;
    //width: calc(100% - 14rem);
    padding: 0 9rem;
}
  @media only screen and (min-width: $break-xxl) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}
}

#about-me-text-h4 {
  font-weight: 600;
  font-size: 19px;
}

.about-me-icons {
  max-width: 700px;
  margin: auto;
}

.icons-container {
  display: flex;
  justify-content: center;
  //gap: 30px;
  flex-wrap: wrap;
}

.icons-container h4 {
  margin-top: 10px;
  text-align: center;
}

.about-me-links {
  color: $green-brand;
}

.about-me-links:hover {
  color: $green-brand;
  text-decoration: underline;
}

.logo-icon {
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.sass-icon,
.html-icon,
.css-icon,
.axure-icon,
.js-icon,
.jquery-icon,
.sql-icon,
.php-icon,
.wordpress-icon,
.react-icon {
  content: "";
  background-color: $green-brand;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  color: #21244a;
  transition: 0.2s ease all;
  cursor: default;
  margin: 7px;
  transform: scale(.8);
  @media only screen and (min-width: $break-l) {
    transform: scale(1);
}
}

.axure-icon img,
.jquery-icon img {
  width: 50%;
}

.sass-icon:hover,
.html-icon:hover,
.css-icon:hover,
.axure-icon:hover,
.js-icon:hover,
.jquery-icon:hover,
.sql-icon:hover,
.php-icon:hover,
.wordpress-icon:hover,
.react-icon:hover {
  transform: scale(1.2);
 }