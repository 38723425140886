//NAVBAR container - not visible for screens smaller than M
.navbar {
  // display: inline-block;
  position: fixed;
  background-color: white;
  z-index: 1;
  height: 100vh;
  width: 170px;
  @media only screen and (min-width: $break-m) {
    display: block !important;
    position: fixed;
    top: -55px;
    left: -32px;
    height: 108vh;
    margin-left: 2rem;
    margin-right: 2rem;
    border-right: 2px $green-brand solid;
    z-index: 1;
    width: 191px;
    max-height: none;
}
//code for small height of the screen. It adds a scroll button on the right of the navbar
@media only screen and (max-height: $break-hs) {
  //top: 5px;
  //height: 100vh;
  overflow-y: overlay;
}
}

//The hamburger button for small screens
.hamburger {
  background-color: white;
  height: 42px;
  width: 40px;
  border: 1px $green-brand solid;
  border-radius: 4px;
  position: fixed;
  top: 0%;
  z-index: 101;
  margin: 10px;
  @media only screen and (min-width: $break-m) {
    display: none;
}
}

#menu-lines {
  padding: 5px 0 4px 3px;
  @media only screen and (min-width: $break-m) {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 33px;
    margin: 10px auto 5px;
}
}

.menu-line {
  width: 33px;
  height: 3px;
  background-color: $green-brand;
  margin-bottom: 5px;
}

.hamburger-title {
  font-size: 10px;
  font-weight: 600;
  position: relative;
  top: -17px;
  left: 5px;
  color: $green-brand;
}

//The A letter in the top left corner of the navbar
.logo {  
  @media only screen and (max-height: $break-hs) {
    margin-bottom: 0;
    margin-top: -30%;
    width:auto !important;
    top: 2rem;
}
  margin-bottom: 0;
  font-size: 6rem;
  font-family: Brush Script MT;
  color: $green-brand;
  border-bottom: 2px $green-brand solid;
  position: relative;
  top: 1rem;
  width: 138px;
  padding-left: 2rem;
@media only screen and (min-width: $break-m) {
  font-size: 10rem;
  line-height: 188px;
  margin-bottom: 5rem;
  margin-top: 1rem;
  width: 160px;
}
}

.logo a {
  color: $green-brand;
  position: relative;
  left: 0;
  padding-left: 30px;
  @media only screen and (min-width: $break-m) {
    padding-left: 0;
}
}

//The stylinig for the links in the navbar
.navbar-items {
  font-weight: 600;
  line-height: 90px;
  padding-bottom: 2rem;
  padding-left: 10px;
  @media only screen and (min-width: $break-m) {
  padding-left: 28px;
}
}

.navbar-items a {
  color: black;
}

.contact-me a {
  color: black;
  position: relative;
  left: -22px;
  @media only screen and (min-width: $break-m) {
    left: -5px;
}  
}

.about-me {
  border-bottom: 2px transparent solid;
  width: 5rem;
  padding-bottom: 1px;
  height: 60px;
}

.about-me .active {
  border-bottom: 2px $green-brand solid;
  padding-bottom: 8px;
  color: $green-brand;
}

.about-me:hover {
  border-bottom: 2px $green-brand solid;
}

.my-portfolio {
  border-bottom: 2px transparent solid;
  width: 7rem;
  padding-bottom: 1px;
  height: 60px;
}

.my-portfolio .active {
  border-bottom: 2px $green-brand solid;
  padding-bottom: 8px;
  color: $green-brand;
}
.my-portfolio:hover {
  border-bottom: 2px $green-brand solid;
}

.coding-examples {
  border-bottom: 2px transparent solid;
  width: 10rem;
  padding-bottom: 1px;
  height: 60px;
}

.coding-examples:hover {
  border-bottom: 2px $green-brand solid;
}

.scs {
  border-bottom: 2px transparent solid;
  width: 7rem;
  padding-bottom: 1px;
  height: 60px;
}

.scs:hover {
  border-bottom: 2px $green-brand solid;
}

.contact-me {
  font-weight: 600;
  border-bottom: 2px $green-brand solid;
  border-top: 2px $green-brand solid;
  padding: 8px 14px 8px 33px;
  width: 122px;
  @media only screen and (min-width: $break-m) {
    width: 144px;
}
}

.social-links {
  display: flex;
  justify-content: left;
  gap: 10px;
  margin-top: 1rem;
  padding-right: 15px;
  padding-left: 4px;
  @media only screen and (min-width: $break-m) {  
  padding-left: 18px;
}
}

.facebook,
.twitter, 
.github, 
.linkedin {
  color: $green-brand;
  padding: 8px;
}

.social-logo {
  font-size: 25px;
}

.social-logo:hover {
    transform: scale(1.5);
}

//Added this div to make it fill the space when sidebar is open so that user can click on it (or anywhere) to close the sidebar
.blocker {
  // display: none;
  position: fixed;
  top: 0;
  left: 170px;
  bottom: 0;
  right: -260px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  // border: 5px yellow solid;
}
