
.scs-container {
  position: relative;
  left: 0rem;
	height: 30vh;
  @media only screen and (min-width: $break-m) {
    left: 12rem;
    width: calc(100% - 12rem);
}
  @media only screen and (min-width: $break-xl) {
    height: 42vh;
}
}

.scs-title {
  max-width: 1000px;
  z-index: 0;
}

.scs-title img {
  width: 49%;
  margin: 1px;
}

.scs-content {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.scs-hero {
  background-image: url(../images/scion-scheme.jpg);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  left: 0%;
	height: 42vh;
  width: 100%;
}

.gradient-div-scs {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  position: absolute;
  width: inherit;
  height: inherit;
}

.scs-hero h1 {
  color: white;
  text-align: center;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);  
  width: 55%;
  @media screen and (-ms-high-contrast: none) {
    width: auto;
}
  @media only screen and (min-width: $break-s) {
    //top: 22%;
    width: 64%;
    @media screen and (-ms-high-contrast: none) {
      width: auto;
}
}
}