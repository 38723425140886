//Contact area container
.contact {
  @media only screen and (min-width: $break-m) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
  @media only screen and (min-width: $break-xl) {
    flex-direction: row;
    margin-top: 2rem;
}
  @media only screen and (min-width: $break-xxl) {
    max-width: 1650px;
    justify-content: space-between;
}
}

//I have added second container in order to fix the position of the elements for all screen sizes
.contact-2 {
  @media only screen and (min-width: $break-xl) {
    width: 58rem;
    display: flex;
}
  @media only screen and (min-width: $break-xxl) {
    margin-left: auto;
    margin-right: auto;
    width: 83rem;
}
}

//The Get In Touch title text styling 
.contact h2 {
  margin-left: 8px;
  font-weight: 600;
  @media only screen and (min-width: $break-l) {
    margin-left: 0;
}
  @media only screen and (min-width: $break-xl) {
    margin-left: -10px;
}
}

//Get In Touch part of the contact styling
.get-in-touch {
  @media only screen and (min-width: $break-m) {
    overflow: hidden;
}
  @media only screen and (min-width: $break-xl) {
    max-width: 23rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
}
  @media only screen and (min-width: $break-xxl) {
    margin-left: 0;
}
}

.get-in-touch-2 {
  padding-left: 11px;
  @media only screen and (min-width: $break-m) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
  @media only screen and (min-width: $break-l) {
    padding-left: 0;
    max-width: none;
    width: 716px;
}
  @media only screen and (min-width: $break-xl) {
    width: 350px;
}
}

//The contact form itself
.contact-form-2 {
  @media only screen and (min-width: $break-m) {
    margin-left: auto;
    margin-right: auto;
    max-width: 610px;
}  
  @media only screen and (min-width: $break-l) {
    width: 750px;
    max-width: none;
}
  @media only screen and (min-width: $break-xl) {
    width: 548px;
    margin-top: 1rem;
}
  @media only screen and (min-width: $break-xxl) {
    width: 932px;
}
}

.contact-form {
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (min-width: $break-xl) {
    max-width: 35rem;
}
  @media only screen and (min-width: $break-xxl) {
    width: 59rem;
    max-width: none;
}
}

//The list of items in the Get In Touch part
.contact-ul {
  list-style-type: none;
  margin-right: 19px;
  padding-left: 9px;
  @media only screen and (min-width: $break-l) {
    padding-left: 0;
}
  @media only screen and (min-width: $break-xl) {
    margin-left: -7px;
}
}

//Styling of the phone number and email address
.phone {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.phone a{
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
  font-size: 14px;
  @media only screen and (min-width: $break-m) {
    font-size: 1.3rem;
}
}

.phone a:hover {
  color: $green-brand;
  text-decoration: underline;
}

//The contact form styling
.contact-form input, textarea {
  font-family: "Helvetica", "Arial", sans-serif;
  background-color: #DDDDDD;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  height: 30px;
  margin: .3rem auto;
  width: 93%;
  margin-left: 8px;
  margin-right: 8px;
  @media only screen and (min-width: $break-m) {
    width: 96%;
}
  @media only screen and (min-width: $break-l) {
    width: 98.2%;
    margin-left: 0px;
}
  @media only screen and (min-width: $break-xl) {
    width: 97%;
}
  @media only screen and (min-width: $break-xxl) {
    width: 98%;
}
}

//Form styling on focus
.contact-form input:focus {
  border: 1px solid #DDDDDD;
  outline: 0;
  background-color: white;
}

//Specific styling for each element of the form
#fname {
  //width: 100%;
  @media only screen and (min-width: $break-m) {
    width: 36.8% !important;
}
}

#lname {  
  //width: 100%;
  margin-right: 0;
  @media only screen and (min-width: $break-m) {
    width: 54.8% !important;  
    margin-left: 0 !important;
}
  @media only screen and (min-width: $break-l) {
    width: 58.2% !important;
}
  @media only screen and (min-width: $break-xl) {
    width: 56% !important;
}
  @media only screen and (min-width: $break-xxl) {
    width: 58.5% !important;
}
}

#message {
  height: 2rem !important;
  padding-bottom: 10.3rem;
  padding-top: 8px;
}

#submit {
  background-color: white !important;
  color: black;
  border: 2px black solid !important;
  font-weight: 600;
  width: 130px !important;
  height: 40px;
  padding-right: 12px;
}


input:focus:invalid {
  border: 1px red solid;
}

form label {
  color: red;
  display: none;
  font-weight: 600;
}








