//Hero image container
.hero-image {
  background-image: url(../images/ny.jpg);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
	height: 100vh;
  width: 100%;
  position: relative;

//code for IE only
  @media screen and (-ms-high-contrast: none) {
    overflow: hidden;
}
}

//The text in hero image. I managed to get it centred to the page for small screens and then centred to the hero image
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 800;
}

//Scroll down text styling
.scroll {
  position: absolute;
  left: 50%;
  bottom: 2%;
  transform: translate(-50%, -50%);
}

//Styling for the down arrow
.fa-chevron-down {
  position: absolute;
  left: 39%;
  bottom: -86%;
  @media only screen and (min-width: $break-m) {
    left: 42%;
    top: 130%;
}
}

.scroll-down-arrow {
  color: white;
  cursor: pointer;
}

//Scroll up styling
.scroll-up {
  max-width: 1650px;
  text-align: center;
  cursor: pointer;
}

.scroll-up-text:visited {
  color: black;
}

h1 {  
  font-size: 40px;
  @media only screen and (min-width: $break-l) {
    font-size: 60px;
}
}