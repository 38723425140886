//The image container
.coding-examples-container {
  //border: springgreen 2px solid;
  @media only screen and (min-width: $break-m) {
    margin-left: 12rem;
}
}

//Hero image
.coding-examples-hero {
  background-image: url(../images/coding-examples.jpg);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  left: 0%;
	height: 42vh;
  width: 100%;
  @media only screen and (min-width: $break-s) {
}
}

//Gradient div
.gradient-div {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  position: absolute;
  width: inherit;
  height: inherit;
}

//Text in hero image
.coding-examples-hero h1 {
  color: white;
  text-align: center;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);  
  width: 55%;
  @media screen and (-ms-high-contrast: none) {
    width: auto;
}
  @media only screen and (min-width: $break-s) {
    //top: 22%;
    width: 64%;
    @media screen and (-ms-high-contrast: none) {
      width: auto;
}
}
}


#second-line {
  color: white;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  @media only screen and (min-width: $break-s) {
    top: 58%;
    width: 64%;
}  
  @media screen and (-ms-high-contrast: none) {
    width: auto;
}
}

//Container for all examples
.coding-examples-content-container {
  //border: 2px red solid;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (min-width: $break-m) {  
    display: flex;
}
}

//Container for each example
.coding-example {
  //border: 2px hotpink solid;
  margin: 2rem;
  width: 100%;
  @media only screen and (min-width: $break-s) {
    width: 100%;
}
  @media only screen and (min-width: $break-xxl) {
}
}

//Scroll-up button tailored to this page as the one from the index file does not centre correctly here
.scroll-up-examples {
  max-width: 1650px;
  text-align: center;
  color: black;
  @media only screen and (min-width: $break-m) {
    margin-left: 12rem;
}
}

.scroll-up-text {
  color: black;
}

//The text I have added to each example with explanation. I added code for all of them but then had to add some more for each separately as they have different length
.coding-text {
  float: right;
  @media only screen and (min-width: $break-l) {
    margin-top: 0 !important;
    margin-left: 36rem !important;
}
}

.coding-text-1 {
  //border: aqua 2px solid;
  margin-left: 8px;
  margin-top: 11rem;
  @media only screen and (min-width: $break-s) {
    margin-top: 18rem;
}
}

.coding-text-2 {
  margin-left: 10px;
  margin-top: 9rem;
  @media only screen and (min-width: $break-s) {
    margin-top: 14rem;
}
}

.coding-text-3 {
  margin-left: 10px;
  margin-top: 11rem;
  @media only screen and (min-width: $break-s) {
    margin-top: 16rem;
}
}

//Code to move the table to the right place 
.example-3-table {
  float: left;
  margin-left: 10px;
  border-collapse: collapse;
  @media only screen and (min-width: $break-l) {
    margin-top: 0 !important;
    margin-left: 36rem !important;
}
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #4caf50;
  color: white;
}

.coding-text-4 {
  margin-left: 10px;
  margin-top: 10rem;
}

.coding-text-5 {
  margin-left: 10px;
  margin-top: 10rem;
}

.coding-text-6 {
  margin-left: 10px;
  margin-top: 10rem;
}

//I added a snippet of the code so that users can copy it
.coding-snippet {
  //border: springgreen 2px solid;
  background-color: black;
  color: white;
  font-size: 10px;
  @media only screen and (min-width: $break-s) {
    font-size: large;
}  
  @media only screen and (min-width: $break-l) {
    max-width: 560px;
}
}

